var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.ModalItem,{attrs:{"title":"发货","bodyStyle":{ overflow: 'hidden' }},on:{"ok":(onloading, cal) => _setup.onShipments(onloading, cal),"close":_setup.onClose},scopedSlots:_vm._u([{key:"default",fn:function({ click }){return [_vm._t("default",function(){return [_c('a',{on:{"click":function($event){return click()}}},[_vm._v("发货")])]},{"click":click})]}},{key:"modal",fn:function(){return [_c('a-form',{staticClass:"view-form",attrs:{"labelAlign":"left","label-col":{ span: 6 },"wrapper-col":{ span: 14 }}},[_c('a-form-item',{attrs:{"label":"买家昵称"}},[_c('span',{staticClass:"span-control"},[_vm._v(_vm._s(_vm.record.buyer.nickname))])]),_c('a-form-item',{attrs:{"label":"买家手机号"}},[_c('span',[_vm._v(_vm._s(_vm.record.buyer.mobile))])]),_c('a-form-item',{attrs:{"label":"收货人姓名"}},[_c('span',{staticClass:"span-control"},[_vm._v(_vm._s(_vm.record.express_name))])]),_c('a-form-item',{attrs:{"label":"收货人手机号"}},[_c('span',{staticClass:"span-control"},[_vm._v(_vm._s(_vm.record.express_mobile))])]),_c('a-form-item',{attrs:{"label":"收货人地址"}},[_c('span',{staticClass:"span-control"},[_vm._v(_vm._s(_vm.record.express_address))])])],1),_c('a-form',{staticStyle:{"position":"relative"},attrs:{"form":_setup.form,"label-col":{ span: 4 },"wrapper-col":{ span: 16 }}},_vm._l((_setup.form.getFieldValue('keys')),function(k,index){return _c('div',{key:k,staticClass:"add-parcel"},[_c('div',{staticClass:"modal-item"},[_c('a-form-item',{attrs:{"label":"物流公司"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `company[${k}]`,
                {
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    {
                      required: true,
                      message: '请选择物流公司',
                    },
                  ],
                },
              ]),expression:"[\n                `company[${k}]`,\n                {\n                  validateTrigger: ['change', 'blur'],\n                  rules: [\n                    {\n                      required: true,\n                      message: '请选择物流公司',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"normal-input",staticStyle:{"width":"200px"}},[_c('a-select-option',{key:"1",attrs:{"value":"顺丰快递"}},[_vm._v("顺丰快递")]),_c('a-select-option',{key:"2",attrs:{"value":"韵达快递"}},[_vm._v("韵达快递")]),_c('a-select-option',{key:"3",attrs:{"value":"中通快递"}},[_vm._v("中通快递")]),_c('a-select-option',{key:"4",attrs:{"value":"圆通快递"}},[_vm._v("圆通快递")]),_c('a-select-option',{key:"5",attrs:{"value":"申通快递"}},[_vm._v("申通快递")])],1)],1)],1),_c('div',{staticClass:"modal-item"},[_c('a-form-item',{attrs:{"label":"快递单号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `number[${k}]`,
                {
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    {
                      required: true,
                      message: '请输入快递单号',
                    },
                  ],
                },
              ]),expression:"[\n                `number[${k}]`,\n                {\n                  validateTrigger: ['change', 'blur'],\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入快递单号',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"normal-input",staticStyle:{"width":"200px"}})],1)],1),(_setup.form.getFieldValue('keys').length != 1)?_c('a-icon',{staticClass:"minus-button",attrs:{"type":"minus-circle","theme":"filled"},on:{"click":function($event){return _setup.minusParcel(k)}}}):_vm._e()],1)}),0),_c('div',{staticClass:"add-button"},[_c('a-icon',{attrs:{"type":"plus-circle","theme":"filled"},on:{"click":_setup.addParcel}})],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }