export const SELECT_LIST: any = [
    {
        value: 1,
        label: '订单编号',
    },
    {
        value: 2,
        label: '收货人姓名',
    },
    {
        value: 3,
        label: '收货人手机号',
    },
    {
        value: 4,
        label: '收货人地址',
    },
    {
        value: 5,
        label: '客户ID',
    },
];
export const STATUS_LIST: any = [
    {
        value: 0,
        label: '全部',
    },
    {
        value: 1,
        label: '待支付',
    },
    {
        value: 2,
        label: '待发货',
    },
    {
        value: 3,
        label: '待收货',
    },
    {
        value: 4,
        label: '已收货',
    },
    {
        value: 5,
        label: '已取消',
    },
    {
        value: 6,
        label: '已关闭',
    },
];
export const PAY_TYPE_LIST: any = [
    {
        value: 0,
        label: '全部',
    },
    {
        value: 1,
        label: '待接单',
    },
    {
        value: 2,
        label: '待抢单',
    },
    {
        value: 3,
        label: '超时未抢单',
    },
    {
        value: 4,
        label: '待取货',
    },
    {
        value: 5,
        label: '待送达',
    },
    {
        value: 6,
        label: '已送达',
    },
    {
        value: 7,
        label: '超时送达',
    },
];
